import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/uireact/uireact/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import * as packageJson from '../package.json';
import Playground from '../../../src/Playground';
import { UiTable } from '../src';
import { UiBadge } from '@uireact/badge';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <UiBadge category="warning" mdxType="UiBadge">❗️ Beta</UiBadge>
    <h1 {...{
      "id": "uitable"
    }}>{`UiTable`}</h1>
    <sup>
  <a href="https://github.com/inavac182/ui-react/blob/main/packages/COMPONENT-NAME/" target="_blank">
    v{packageJson.version}
  </a>
    </sup>
    <blockquote>
      <p parentName="blockquote">{`Used to render information in a table that is filterable and sortable.
The rows can be selectable if the `}<inlineCode parentName="p">{`onSelect`}</inlineCode>{` CB is provided.`}</p>
    </blockquote>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <blockquote>
      <p parentName="blockquote">{`npm i @uireact/foundation @uireact/table`}</p>
    </blockquote>
    <h2 {...{
      "id": "uitable-1"
    }}>{`UiTable`}</h2>
    <Playground __position={2} __code={'<UiTable\n  data={{\n    headings: [\'No\', \'Summary\', \'Price\'],\n    items: [\n      { id: \'1\', cols: [\'1\', \'item 1\', \'$10\'] },\n      { id: \'2\', cols: [\'2\', \'item 2\', \'$20\'] },\n      { id: \'3\', cols: [\'3\', \'item 3\', \'$30\'] },\n      { id: \'4\', cols: [\'4\', \'item 4\', \'$40\'] },\n      { id: \'5\', cols: [\'5\', \'item 5\', \'$50\'] },\n      { id: \'6\', cols: [\'6\', \'item 6\', \'$60\'] },\n      { id: \'7\', cols: [\'7\', \'item 7\', \'$70\'] },\n      { id: \'8\', cols: [\'8\', \'item 8\', \'$80\'] },\n      { id: \'9\', cols: [\'9\', \'item 9\', \'$90\'] },\n      { id: \'10\', cols: [\'10\', \'item 10\', \'$100\'] },\n    ],\n  }}\n  selected=\"3\"\n  onClick={id => {\n    selected = id\n    console.log(`id ${id} selected`)\n  }}\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTable,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTable data={{
        headings: ['No', 'Summary', 'Price'],
        items: [{
          id: '1',
          cols: ['1', 'item 1', '$10']
        }, {
          id: '2',
          cols: ['2', 'item 2', '$20']
        }, {
          id: '3',
          cols: ['3', 'item 3', '$30']
        }, {
          id: '4',
          cols: ['4', 'item 4', '$40']
        }, {
          id: '5',
          cols: ['5', 'item 5', '$50']
        }, {
          id: '6',
          cols: ['6', 'item 6', '$60']
        }, {
          id: '7',
          cols: ['7', 'item 7', '$70']
        }, {
          id: '8',
          cols: ['8', 'item 8', '$80']
        }, {
          id: '9',
          cols: ['9', 'item 9', '$90']
        }, {
          id: '10',
          cols: ['10', 'item 10', '$100']
        }]
      }} selected="3" onClick={id => {
        selected = id;
        console.log(`id ${id} selected`);
      }} mdxType="UiTable" />
    </Playground>
    <ul>
      <li parentName="ul">{`If a row is selected the `}<inlineCode parentName="li">{`onClick`}</inlineCode>{` CB is executed`}</li>
      <li parentName="ul">{`If you pass a `}<inlineCode parentName="li">{`selected`}</inlineCode>{` id to the table that row will show as selected in the table`}</li>
    </ul>
    <h2 {...{
      "id": "uitable---with-category"
    }}>{`UiTable - with category`}</h2>
    <Playground __position={3} __code={'<UiTable\n  data={{\n    headings: [\'Summary\', \'Price\'],\n    items: [{ id: \'1\', cols: [\'item 1\', \'$10\'] }],\n  }}\n  category=\"tertiary\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTable,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTable data={{
        headings: ['Summary', 'Price'],
        items: [{
          id: '1',
          cols: ['item 1', '$10']
        }]
      }} category="tertiary" mdxType="UiTable" />
    </Playground>
    <h2 {...{
      "id": "uitable---with-filter-box-position"
    }}>{`UiTable - with filter box position`}</h2>
    <Playground __position={4} __code={'<UiTable\n  data={{\n    headings: [\'Summary\', \'Price\'],\n    items: [{ id: \'1\', cols: [\'item 1\', \'$10\'] }],\n  }}\n  category=\"tertiary\"\n  filterBoxPosition=\"right\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Props,
      packageJson,
      Playground,
      UiTable,
      UiBadge,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UiTable data={{
        headings: ['Summary', 'Price'],
        items: [{
          id: '1',
          cols: ['item 1', '$10']
        }]
      }} category="tertiary" filterBoxPosition="right" mdxType="UiTable" />
    </Playground>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <Props of={UiTable} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      